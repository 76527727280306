body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@300&display=swap');
:root
{
    --main-color:#0aad0a;
    --light-color:#f0f3f2;
    --shadow: rgba(145,158,171,.2) 0px 2px 4px -1px,rgba(145,158,171,.14) 0px 4px 5px 0px,rgba(145,158,171,.12) 0px 1px 10px 0px;
    --font-family:'Encode Sans Expanded', sans-serif;
    --rating-color:#ffc908;
}

body{
    font-family: var(--font-family) !important;
}
.rating-color
{
    color: var(--rating-color);
}
.cursor-pointer
{
    cursor: pointer;
}
a , a:hover{
    color: black;
    text-decoration: none;
}
.product:hover
{
    box-shadow: var(--shadow);
    border: solid 1px var(--main-color);
}
.product .btn 
{
    transition: transform 1s , opacity 1s;
    transform: translateY(100%);
    opacity: 0;
}
.product:hover .btn 
{
    opacity: 1;
    transform: translateY(0%);

}
.font-sm
{
    font-size: 14px;
}
.text-main
{
    color: var(--main-color) !important;
}
.bg-main
{
    background-color: var(--main-color) !important;
}
.bg-main-light 
{
    background-color: var(--light-color) !important;
}
.hig{
    height: 200px;
}
.vh{
    height: 50vh;
}
.navbar-nav .nav-link.active{
    color:#0aad0a !important;
    font-weight: bolder;
}
.offline{
    position: fixed;
    bottom: 100px;
    right: 150px;
    background-color: #0aad0aed;
    padding: 10px 50px;
    border-radius: 30px;
    color: #ffff;
}
.foot{
    height:500px;
    display: flex;
    align-items: center ;
    justify-content: center;
    background-color: #dcdcdc;
}
.SerImg{
    transition: all 2s;
    /* overflow: hidden; */
  }
  .SerImg:hover{
    transform: scale(1.2);
  }
  .h{
    height: 600px;
  }
 